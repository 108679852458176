export const ACCOUNT_URL = '/account';
export const ACCOUNT_SUB_URL = '/account';
export const ACCOUNT_LOGS_SUB_URL = ACCOUNT_URL.concat('logs');

export const WALLET_URL = '/wallet';

export const REPORTS_URL = '/reports';
export const REPORTS_PRODUCTS_URL = '/reports/productsReport/products';
export const REPORTS_PLAYERS_URL = '/reports/productsReport/players';
export const LINK_STATISTICS_URL = '/reports/marketing/linkStatistics';
export const WALLET_REPORT = '/walletReport';
export const USERS_REPORT = '/network/customers';

export const RESET_PASSWORD = '/resetPassword';
export const REGISTRATION_SUCCESS = '/registrationSuccess';
export const EXPIRED_TOKEN = '/expiredToken';

export const LOGIN = '/login';
export const REGISTER = '/register';
export const FORGOT_PASSWORD = '/forgotPassword';
export const FORGOT_PASSWORD_PHONE_NUMBER = '/forgotPassword/phoneNumber';
export const FORGOT_PASSWORD_RESET_LINK_SENT = '/forgotPassword/resetLinkSent';
export const FORGOT_PASSWORD_EMAIL = '/login/forgotPassword/email';
export const RESET_LINK_SENT = '/login/forgotPassword/resetLinkSent';
export const LOGIN_PHONE_NUMBER = '/login/forgotPassword/phoneNumber';
export const RESEND_VERIFICATION = '/login/forgotPassword/resendVerification';
export const FORGOT_PASSWORD_OPTION = '/login/forgotPassword/selectRestoreOption';
export const DOCUMENT_VERIFICATION = '/documentVerification';

export const ERROR_IMAGE_SOURCE = '/assets/media/errorImage.png';
export const IMAGE_PATH = '/global/img/';

export const REDIRECT_URL_BY_KEY = {
  banners: 'tools/mediaLibrary',
  dataFeed: 'tools/dataFeed/json',
};

export const HELP_DOWNLOAD_PATH = '/data/help/';
export const GEOCODE_URL = 'https://api.bigdatacloud.net/data/reverse-geocode-client';
export const POSTBACK_CREATE_EDIT = '/configuration/postBack/createEdit';
export const POSTBACK_LIST = '/configuration/postBack';
export const COMMISSION_URL = '/configuration/commissionPlan';
export const COMMISSION_ARCHIVED_URL = '/configuration/commissionPlan/archived';
export const POSTBACK_CHANGE_LOG = '/account/:id/logs/postbackChangeLog';
export const CONFIGURATION = '/configuration';

export const CHAT_LIST_PATH = '/messages/:tab(chats|scheduled)';
export const CONVERSATION_PATH = `${CHAT_LIST_PATH}/:userId`;
export const MESSAGES_PATH = `${CHAT_LIST_PATH}/:userId?`;

export const TERMS_AND_CONDITIONS = '/TermsAndConditions';

export const PLAYERS_PATH = '/players/players';
export const PLAYERS_COMMISSION_LOG_PATH = '/players/players/commissionLogs';
